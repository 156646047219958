import React, { useState } from 'react';
import { BiSearch } from 'react-icons/bi';

function CreateAdCampaign() {
    const [goal, setGoal] = useState('');
    const [targetAudience, setTargetAudience] = useState({
        age: '',
        gender: '',
        location: '',
        interests: '',
        behaviors: ''
    });
    const [budget, setBudget] = useState({
        total: 0,
        dailyLimit: '',
        bidStrategy: 'CPC' // Default to CPC
    });
    const [hashtags, setHashtags] = useState('');
    const [adFrequency, setAdFrequency] = useState('');
    const [walletAmount, setWalletAmount] = useState(1000); // Sample wallet amount
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const handleGoalChange = (e) => setGoal(e.target.value);
    const handleTargetAudienceChange = (e) => {
        setTargetAudience({ ...targetAudience, [e.target.name]: e.target.value });
    };
    const handleBudgetChange = (e) => {
        setBudget({ ...budget, [e.target.name]: e.target.value });
    };
    const handleSliderChange = (e) => {
        setBudget({ ...budget, total: e.target.value });
    };
    const handleHashtagsChange = (e) => setHashtags(e.target.value);
    const [selectedFrequency, setSelectedFrequency] = useState('');

    const handleFrequencyChange = (event) => {
        setSelectedFrequency(event.target.value);
    };

    const handleSubmit = () => {
        // Check if wallet has enough amount
        if (budget.total > walletAmount) {
            alert('Insufficient wallet amount');
            return;
        }

        // Submit form logic here
        setIsFormSubmitted(true);
    };

    const [searchQuery, setSearchQuery] = useState('');
    const [productLink, setProductLink] = useState('');
    const [selectedProduct, setSelectedProduct] = useState(null);

    const handleSearch = () => {
        // Logic for searching the product based on `searchQuery`
        // You can replace this with API calls or other search logic
        console.log(`Searching for: ${searchQuery}`);
    };

    const handleLinkSubmit = () => {
        // Logic for handling product link submission
        // You can replace this with API calls or other link handling logic
        console.log(`Product link submitted: ${productLink}`);
    };

    return (
        <div className='create-campaign'>
            <div className="add-product-header">
                <div>
                    <h1>Create Campaign</h1>
                    <p style={{ marginTop: -20 }}>Define your campaign details</p>
                </div>
                {/* <div style={{ display: 'flex' }}>
                    <button className="bulk-update-button">Bulk Upload CSV</button>
                    <button className="save-product-button">Save Campaign</button>
                </div> */}
            </div>
            <div className="product-form">
                <div className="form-section basic-info">
                    <h3>Campaign Objective</h3>
                    <label htmlFor="campaign-goal">Define the Goal</label>
                    <div className='product-select'>
                        <select
                            id="bid-strategy"
                            name="bidStrategy"
                            value={budget.bidStrategy}
                            onChange={handleBudgetChange}
                        >
                            <option value="Select">Select Campaign Goal</option>
                            <option value="Brand Awareness">Brand Awareness</option>
                            <option value="Conversions">Conversions</option>
                            <option value="Lead Generation">Lead Generation</option>
                            <option value="Traffic">Traffic</option>
                            <option value="Engagement">Engagement</option>
                            <option value="Video Views">Video Views</option>
                            <option value="Messages">Messages</option>
                            <option value="Catalog Sales">Catalog Sales</option>
                            <option value="Store Traffic">Store Traffic</option>
                        </select>
                    </div>
                </div>

                <div className="form-section target-audience">
                    <h3>Target Audience</h3>
                    <label htmlFor="age">Age</label>
                    <div className='product-input'>
                        <input
                            type="text"
                            id="age"
                            name="age"
                            value={targetAudience.age}
                            onChange={handleTargetAudienceChange}
                            placeholder="Age Range"
                        />
                    </div>
                    <label htmlFor="gender">Gender</label>
                    <div className='product-input'>
                        <input
                            type="text"
                            id="gender"
                            name="gender"
                            value={targetAudience.gender}
                            onChange={handleTargetAudienceChange}
                            placeholder="Gender"
                        />
                    </div>
                    <label htmlFor="location">Location</label>
                    <div className='product-input'>
                        <input
                            type="text"
                            id="location"
                            name="location"
                            value={targetAudience.location}
                            onChange={handleTargetAudienceChange}
                            placeholder="Location"
                        />
                    </div>
                    <label htmlFor="interests">Interests</label>
                    <div className='product-input'>
                        <input
                            type="text"
                            id="interests"
                            name="interests"
                            value={targetAudience.interests}
                            onChange={handleTargetAudienceChange}
                            placeholder="Interests"
                        />
                    </div>
                    <label htmlFor="behaviors">Behaviors</label>
                    <div className='product-input'>
                        <input
                            type="text"
                            id="behaviors"
                            name="behaviors"
                            value={targetAudience.behaviors}
                            onChange={handleTargetAudienceChange}
                            placeholder="Behaviors"
                        />
                    </div>
                </div>

                <div className="form-section ad-budget">
                    <h3>Ad Budget</h3>
                    <label htmlFor="total-budget">Total Budget</label>
                    <div className="slider-container">
                        <input
                            type="range"
                            id="total-budget"
                            name="total"
                            min="0"
                            max="5000"
                            value={budget.total}
                            onChange={handleSliderChange}
                            className="custom-slider"
                        />
                        <div className="slider-value">{budget.total} $</div>
                    </div>

                    <label htmlFor="daily-limit">Daily Spend Limit</label>
                    <div className='product-input'>
                        <input
                            type="text"
                            id="daily-limit"
                            name="dailyLimit"
                            value={budget.dailyLimit}
                            onChange={handleBudgetChange}
                            placeholder="Daily Spend Limit"
                        />
                    </div>
                    <label htmlFor="bid-strategy">Bid Strategy</label>
                    <div className='product-select'>
                        <select
                            id="bid-strategy"
                            name="bidStrategy"
                            value={budget.bidStrategy}
                            onChange={handleBudgetChange}
                            className='add-category-select'
                        >
                            <option value="CPC">Cost Per Click (CPC)</option>
                            <option value="CPM">Cost Per Mille (CPM)</option>
                            <option value="CPA">Cost Per Acquisition (CPA)</option>
                        </select>
                    </div>
                </div>
                <div className="form-section product-selection">
                    <h3>Select Product</h3>
                    <div className="search-product">
                        <label htmlFor="search-product" className="search-label">
                            <BiSearch size={20} />
                            <input
                                type="text"
                                id="search-product"
                                placeholder="Search for a product"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <button onClick={handleSearch}>Search</button>
                        </label>
                    </div>
                    <div className="link-product">
                        <label htmlFor="product-link">Or paste product link</label>
                        <input
                            type="text"
                            id="product-link"
                            placeholder="Paste product link"
                            value={productLink}
                            onChange={(e) => setProductLink(e.target.value)}
                        />
                        <button onClick={handleLinkSubmit}>Submit</button>
                    </div>
                    {selectedProduct && (
                        <div className="selected-product">
                            <h4>Selected Product:</h4>
                            {/* Display selected product details */}
                            <p>{selectedProduct.name}</p>
                        </div>
                    )}
                </div>
                <div className="form-section hashtags">
                    <h3>Hashtags</h3>
                    <div className='product-input'>
                        <input
                            type="text"
                            id="hashtags"
                            value={hashtags}
                            onChange={handleHashtagsChange}
                            placeholder="Enter hashtags separated by commas"
                        />
                    </div>
                </div>

                <div className="form-section ad-frequency">
                    <h3>Ad Frequency</h3>
                    <p htmlFor="ad-frequency" style={{ marginBottom: 10 }}>
                        How often do you want to show your ad to the customers?
                    </p>
                    <div className="frequency-options">
                        <label className="frequency-option">
                            <input
                                type="radio"
                                name="ad-frequency"
                                value="Every Hour"
                                checked={selectedFrequency === 'Every Hour'}
                                onChange={handleFrequencyChange}
                            />
                            <span className="checkmark"></span>
                            Every Hour
                        </label>
                        <label className="frequency-option">
                            <input
                                type="radio"
                                name="ad-frequency"
                                value="Daily"
                                checked={selectedFrequency === 'Daily'}
                                onChange={handleFrequencyChange}
                            />
                            <span className="checkmark"></span>
                            Daily
                        </label>
                        <label className="frequency-option">
                            <input
                                type="radio"
                                name="ad-frequency"
                                value="Weekly"
                                checked={selectedFrequency === 'Weekly'}
                                onChange={handleFrequencyChange}
                            />
                            <span className="checkmark"></span>
                            Weekly
                        </label>
                        <label className="frequency-option">
                            <input
                                type="radio"
                                name="ad-frequency"
                                value="Monthly"
                                checked={selectedFrequency === 'Monthly'}
                                onChange={handleFrequencyChange}
                            />
                            <span className="checkmark"></span>
                            Monthly
                        </label>
                    </div>
                </div>
                <div className="form-section ad-preview">
                    <h3>Ad Preview</h3>
                    <div className="ad-preview-placeholder">
                        {/* Display ad preview here */}
                        <div style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                            <h4>Ad Preview</h4>
                            <p>Goal: {goal}</p>
                            <p>Target Audience: {`${targetAudience.age}, ${targetAudience.gender}, ${targetAudience.location}, ${targetAudience.interests}, ${targetAudience.behaviors}`}</p>
                            <p>Budget: {budget.total} USD</p>
                            <p>Daily Limit: {budget.dailyLimit}</p>
                            <p>Bid Strategy: {budget.bidStrategy}</p>
                            <p>Hashtags: {hashtags}</p>
                            <p>Frequency: {adFrequency}</p>
                        </div>
                    </div>
                </div>

                <div className="form-section wallet-check">
                    <h3>Wallet Check</h3>
                    <div className="wallet-card">
                        <div className="wallet-info">
                            <p className="wallet-label">Wallet Amount:</p>
                            <p className="wallet-amount">{walletAmount} USD</p>
                        </div>
                        <div style={{ display: 'flex', gap: 10 }}>
                            <button className="check-wallet-button">Check Balance</button>
                            <button className="topup-wallet-button">Topup Wallet</button>
                        </div>
                    </div>
                </div>

                <div className="form-section submit-section">
                    <button className="bulk-update-button" style={{ width: '100%', margin: 0, marginTop: 10 }}>Accept and Submit</button>
                </div>
            </div>
        </div >
    );
}

export default CreateAdCampaign;
