import React from "react";

const Header = () => {
    const date = new Date();
    const day = date.toLocaleDateString("en-US", { weekday: "long" });
    const month = date.toLocaleDateString("en-US", { month: "long" });
    const year = date.getFullYear();
    const dateString = `${day}, ${date.getDate()} ${month} ${year}`;
    const timeString = date.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" });
    return (
        <div className="header">
            <h2>Welcome back, John Brand</h2>
            <span className="header-date">{dateString} | {timeString}</span>
        </div>
    );
};

export default Header;