import React, { useEffect, useState } from 'react'
import { BiChevronLeft, BiChevronRight, BiFilter, BiSearch } from 'react-icons/bi'
import HawaiianShoes from '../assets/sampleImages/products/hawaiian-shoes.jpg'
import VitaminCSerum from '../assets/sampleImages/products/vitamin-c-serum.jpg'
import SmartWatch from '../assets/sampleImages/products/smart-watch.jpg'
import ChanelN5 from '../assets/sampleImages/products/chanel-n5.jpg'
import PressedOrangeJuice from '../assets/sampleImages/products/pressed-orange-juice.jpg'
import WirelessHeadphones from '../assets/sampleImages/products/wireless-headphones.jpg'
import { Link } from 'react-router-dom'

const products = [
    { id: 1, name: 'Hawaiian Shoes', category: 'Footwear', price: 99.99, inventory: 100, image: HawaiianShoes },
    { id: 2, name: 'Vitamin C Serum', category: 'Skin Care', price: 9.99, inventory: 50, image: VitaminCSerum },
    { id: 3, name: 'Wireless Headphones', category: 'Electronics', price: 199.99, inventory: 10, image: WirelessHeadphones },
    { id: 4, name: 'Smart Watch', category: 'Electronics', price: 159.99, inventory: 100, image: SmartWatch },
    { id: 5, name: 'Chanel Perfume', category: 'Perfume', price: 299.99, inventory: 70, image: ChanelN5 },
    { id: 6, name: 'Fresh Orange Juice', category: 'Beverages', price: 5.99, inventory: 50, image: PressedOrangeJuice },
]

export default function Shop() {
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage, setProductsPerPage] = useState(3); // Default is 3 products per page

    // Calculate the indexes of products for the current page
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    // const [currentProducts, setCurrentProducts] = useState(products.slice(indexOfFirstProduct, indexOfLastProduct));
    const [filteredProducts, setFilteredProducts] = useState(products.slice(indexOfFirstProduct, indexOfLastProduct));
    // Function to go to the next page
    const nextPage = () => {
        if (currentPage < Math.ceil(products.length / productsPerPage)) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    // Function to go to the previous page
    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const [search, setSearch] = useState('');

    useEffect(() => {
        console.log(search);
        let filteredProductsTemp = products.filter(product => product.name.toLowerCase().includes(search.toLowerCase()));
        let slicedFilters = filteredProductsTemp.slice(indexOfFirstProduct, indexOfLastProduct)
        setFilteredProducts(slicedFilters);
    }, [search, productsPerPage, currentPage]);

    return (
        <div className="shop">
            <div className='shop-header'>
                <div className="shop-container">
                    <h1>Shop</h1>
                    <div className="shop-search">
                        <BiSearch color='blue' size={30} />
                        <input type="text" placeholder="Search For Products"
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                        />
                    </div>
                </div>
                <div className="shop-container">
                    <div className="products-per-page">
                        <label htmlFor="productsPerPage">Products per page: </label>
                        <select
                            id="productsPerPage"
                            onChange={(e) => setProductsPerPage(Number(e.target.value))}
                            value={productsPerPage}
                        >
                            <option value={3}>3</option>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                        </select>
                    </div>
                    <button className="filter-button">
                        <BiFilter color='blue' size={30} />
                        Filter
                    </button>

                    <Link to={"/shop/add-product"}
                        style={{ color: 'white', textDecoration: 'none' }}
                        className="add-product-button"
                    >
                        Add Product
                    </Link>
                </div>
            </div>
            {/* <div style={{ maxWidth: '900px', margin: '0 auto', padding: '20px' }}>
                <table style={tableStyles}>
                    <thead>
                        <tr>
                            <th style={thStyles}>Product</th>
                            <th style={thStyles}>Category</th>
                            <th style={thStyles}>Price</th>
                            <th style={thStyles}>Inventory</th>
                            <th style={thStyles}>Edit Product</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((product, index) => (
                            <tr key={product.id} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f8f8f8' }}>
                                <td style={tdStyles}>
                                    <img src={product.image} alt={product.name} style={imageStyles} />
                                    {product.name}
                                </td>
                                <td style={tdStyles}>{product.category}</td>
                                <td style={tdStyles}>${product.price.toFixed(2)}</td>
                                <td style={tdStyles}>{product.inventory} Stock</td>
                                <td style={tdStyles}>
                                    <button style={buttonStyles}>Edit</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
                    <span>Show 6 of 10 Products</span>
                    <div>
                        <button style={{ ...buttonStyles, marginRight: '10px' }}><BiChevronLeft size={16} /></button>
                        <span>1 / 2</span>
                        <button style={{ ...buttonStyles, marginLeft: '10px' }}><BiChevronRight size={16} /></button>
                    </div>
                </div>
            </div> */}
            <div class="table-container">
                <table class="product-table">
                    <thead>
                        <tr>
                            <th>Product</th>
                            <th>Category</th>
                            <th>Price</th>
                            <th>Inventory</th>
                            <th>Edit Product</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredProducts.map((product, index) => (
                                <tr key={product.id} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f8f8f8' }}>
                                    <td>
                                        <img src={product.image} alt={product.name} className='product-table-image' />
                                        <span className='product-table-product-name'>{product.name}</span>
                                    </td>
                                    <td className='product-table-category'>{product.category}</td>
                                    <td className='product-table-price'>${product.price.toFixed(2)}</td>
                                    <td className='product-table-inventory'>{product.inventory} Stock</td>
                                    <td>
                                        <button className='product-table-view-btn'>View</button>
                                        <button className='product-table-edit-btn'>Edit</button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

                <div className="pagination">
                    <span>
                        Showing {indexOfFirstProduct + 1} - {Math.min(indexOfLastProduct, products.length)} of {products.length} Products
                    </span>
                    <div className="pagination-controls">
                        <button className="prev-btn" onClick={prevPage} disabled={currentPage === 1}>
                            <BiChevronLeft size={40} color='black' />
                        </button>
                        <span>{currentPage} / {Math.ceil(products.length / productsPerPage)}</span>
                        <button className="next-btn" onClick={nextPage} disabled={currentPage === Math.ceil(products.length / productsPerPage)}>
                            <BiChevronRight size={40} color='black' />
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )
}