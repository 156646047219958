import React from 'react'
import HawaiianShoes from '../assets/sampleImages/products/hawaiian-shoes.jpg'
import VitaminCSerum from '../assets/sampleImages/products/vitamin-c-serum.jpg'
import SmartWatch from '../assets/sampleImages/products/smart-watch.jpg'
import ChanelN5 from '../assets/sampleImages/products/chanel-n5.jpg'
import PressedOrangeJuice from '../assets/sampleImages/products/pressed-orange-juice.jpg'
import WirelessHeadphones from '../assets/sampleImages/products/wireless-headphones.jpg'
import PermfumeSpray from '../assets/sampleImages/products/perfume-spray.jpg'

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
export default function PopularProducts() {
    const productsData = [
        {
            id: 1,
            name: 'Hawaiian Shoes',
            price: 99.99,
            image: HawaiianShoes,
        },
        {
            id: 2,
            name: 'Vitamin C Serum',
            price: 29.99,
            image: VitaminCSerum,
        },
        {
            id: 3,
            name: 'Smart Watch',
            price: 199.99,
            image: SmartWatch,
        },
        {
            id: 4,
            name: 'Chanel N5',
            price: 299.99,
            image: ChanelN5,
        },
        {
            id: 5,
            name: 'Pressed Orange Juice',
            price: 9.99,
            image: PressedOrangeJuice,
        },
        {
            id: 6,
            name: 'Wireless Headphones',
            price: 99.99,
            image: WirelessHeadphones
        },
        {
            id: 7,
            name: 'Perfume Spray',
            price: 49.99,
            image: PermfumeSpray
        }

    ]
    // const swiper = useSwiper();
    return (
        <div className='popular-products-container'>
            <h3 className='popular-products-title'>Most Popular Products</h3>
            <div className='product-cards-container' style={{ width: "90%" }}>
                {/* {productsData.map((product) => (
                    <div key={product.id} className='popular-products-card'>
                        <img src={product.image} alt={product.name} />
                        <div className='product-details'>
                            <h3>{product.name}</h3>
                            <p>${product.price}</p>
                        </div>
                    </div>
                ))} */}
                <Swiper
                    // install Swiper modules
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    slidesPerView={6}
                    spaceBetween={20}
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log('slide change')}
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }}
                >
                    {productsData.map((product) => (
                        <SwiperSlide>
                            <div key={product.id} className='popular-products-card'>
                                <img src={product.image} alt={product.name} />
                                <div className='product-details'>
                                    <h3>{product.name}</h3>
                                    <p style={{ fontWeight: 700 }}>${product.price}</p>
                                </div>
                            </div>
                        </SwiperSlide>

                    ))
                    }
                </Swiper>
                {/* swiper nav buttons */}
                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
            </div>
        </div>
    )
}
