import React from 'react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';
export default function LoyalCustomers() {
    const customersData = [
        {
            id: 1,
            name: 'Nathan Drake',
            orders: 10,
            joinedOn: '2024-01-01',
            image: 'https://randomuser.me/api/portraits/men/1.jpg',
        },
        {
            id: 2,
            name: 'Lara Croft',
            orders: 8,
            joinedOn: '2024-02-15',
            image: 'https://randomuser.me/api/portraits/women/2.jpg',
        },
        {
            id: 3,
            name: 'John Wick',
            orders: 15,
            joinedOn: '2024-03-10',
            image: 'https://randomuser.me/api/portraits/men/3.jpg',
        },
        {
            id: 4,
            name: 'Samus Aran',
            orders: 12,
            joinedOn: '2024-04-20',
            image: 'https://randomuser.me/api/portraits/women/4.jpg',
        },
        {
            id: 5,
            name: 'Kratos',
            orders: 18,
            joinedOn: '2024-05-05',
            image: 'https://randomuser.me/api/portraits/men/5.jpg',
        },
        {
            id: 6,
            name: 'Ellie Williams',
            orders: 9,
            joinedOn: '2024-06-22',
            image: 'https://randomuser.me/api/portraits/women/6.jpg',
        }
    ];

    return (
        <div className='popular-products-container'>
            <h3 className='popular-products-title'>Most Loyal Customers</h3>
            <div className='product-cards-container'>
                {/* {productsData.map((product) => (
                    <div key={product.id} className='popular-products-card'>
                        <img src={product.image} alt={product.name} />
                        <div className='product-details'>
                            <h3>{product.name}</h3>
                            <p>${product.price}</p>
                        </div>
                    </div>
                ))} */}
                <Swiper
                    // install Swiper modules
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    slidesPerView={6}
                    spaceBetween={10}
                    navigation
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log('slide change')}
                >
                    {customersData.map((customer) => (
                        <SwiperSlide>
                            <div key={customer.id} className='popular-products-card'>
                                <img src={customer.image} alt={customer.name} />
                                <div className='product-details'>
                                    <h3>{customer.name}</h3>
                                    <span className='creators-stats'>Orders
                                        <span>{customer.orders}</span>
                                    </span>
                                    <span className='creators-stats'>Joined On
                                        <span>{customer.joinedOn}</span>
                                    </span>
                                </div>
                            </div>
                        </SwiperSlide>

                    ))
                    }
                </Swiper>
            </div>
        </div>
    )
}
