import React from 'react';
import '../styles/StepIndicator.css'; // Import the CSS for styles

const StepIndicator = ({ currentStep }) => {
    const steps = ['Basic Information', 'Contact Details', 'Shop Details', 'Brand Account Details', "Preview and Declaration"];

    return (
        <div className="step-indicator">
            {steps.map((step, index) => (
                <div key={index} className="step-container">
                    <div className={`circle ${index <= currentStep ? 'circle-active' : ''}`}>
                        <div className={`inner-circle ${index <= currentStep ? 'inner-circle-active' : ''}`} />
                    </div>
                    {/* Step Label, positioned to the right of the circle */}
                    <div className={`label ${index <= currentStep ? 'label-active' : ''}`}>
                        {step}
                    </div>
                    {/* Line */}
                    {index < steps.length - 1 && (
                        <div className={`line ${index < currentStep ? 'line-active' : ''}`} />
                    )}
                </div>
            ))}
        </div>
    );
};

export default StepIndicator;
