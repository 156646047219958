import React from "react";
import { FaShippingFast, FaBox, FaMap, FaEnvelope, FaChartBar, FaShoppingBag, FaCreditCard, FaPeopleArrows } from "react-icons/fa";
import GlitchText from "./GlitchText";
import { FaPeopleGroup, FaPowerOff } from "react-icons/fa6";
import { BiSupport } from "react-icons/bi";

const Sidebar = () => {
    const activePage = window.location.pathname;
    const shopPages = ['/shop', '/shop/add-product'];
    const adsPages = ['/ads-management', '/ads-management/create-campaign'];
    const brandAmbassadorsPages = ['/brand-ambassadors', '/brand-ambassadors/requests'];
    return (
        <div className="sidebar">
            <GlitchText text="lynkd" />
            <div className="sidebar-menu">
                <a href="/dashboard" className={activePage === "/dashboard" ? 'active' : undefined}>
                    <FaShippingFast size={26} />
                    <span>Dashboard</span>
                </a>
                <a href="/shop" className={shopPages.includes(activePage) ? 'active' : undefined}>
                    <FaShoppingBag size={26} />
                    <span>Shop</span>
                </a>
                <a href="/orders" className={activePage === "/orders" ? 'active' : undefined}>
                    <FaBox size={26} />
                    <span>Orders</span>
                </a>
                <a href="/messages" className={activePage === "/messages" ? 'active' : undefined}>
                    <FaEnvelope size={26} />
                    <span>Messages</span>
                    <div className="badge">7</div>
                </a>
                <a href="/analytics" className={activePage === "/analytics" ? 'active' : undefined}>
                    <FaChartBar size={26} />
                    <span>Analytics</span>
                </a>
                <a href="/ads-management" className={adsPages.includes(activePage) ? 'active' : undefined}>
                    <FaCreditCard size={26} />
                    <span>Ads</span>
                </a>
                <a href="/brand-ambassadors" className={brandAmbassadorsPages.includes(activePage) ? 'active' : undefined}>
                    <FaPeopleGroup size={26} />
                    <span>Brand Ambassadors</span>
                </a>
                <a href="/contact-support" className={activePage === "/contact-support" ? 'active' : undefined}>
                    <BiSupport size={26} />
                    <span>Contact Support</span>
                </a>
                <a href="/" className="logout-btn">
                    <FaPowerOff size={26} />
                    <span>Logout</span>
                </a>
            </div>
        </div>
    );
};

export default Sidebar;