import React from 'react';
import '../styles/GlitchText.css';

const GlitchText = ({ text }) => {
    return (
        <div className="glitch-container">
            <span className="glitch-text" data-text={text}>
                {text}
            </span>
        </div>
    );
};

export default GlitchText;
