import React, { useState, useEffect } from 'react'
import { BiChevronLeft, BiChevronRight, BiPlus } from 'react-icons/bi';

const requests = [
    {
        requestId: 'REQ1234',
        requestSubject: 'Unable to access dashboard',
        requestType: 'Technical Issue',
        requestCategory: 'Dashboard',
        requestSubcategory: 'Access Issues',
        message: 'I am unable to access the dashboard after logging in. The page remains blank.',
        issueScreenshot: 'https://via.placeholder.com/150',
        imageDescription: 'Screenshot of blank dashboard screen',
        requestStatus: 'Open',
        requestDate: '2024-09-18',
        customerName: 'Alice Brown',
        customerImage: 'https://randomuser.me/api/portraits/women/3.jpg'
    },
    {
        requestId: 'REQ5678',
        requestSubject: 'Payment not processing',
        requestType: 'Billing Issue',
        requestCategory: 'Payments',
        requestSubcategory: 'Payment Failures',
        message: 'I am trying to complete my purchase but the payment is not being processed.',
        issueScreenshot: 'https://via.placeholder.com/150',
        imageDescription: 'Error message while processing payment',
        requestStatus: 'In Progress',
        requestDate: '2024-09-16',
        customerName: 'John Miller',
        customerImage: 'https://randomuser.me/api/portraits/men/4.jpg'
    },
    {
        requestId: 'REQ9101',
        requestSubject: 'Unable to update profile picture',
        requestType: 'Account Issue',
        requestCategory: 'Profile',
        requestSubcategory: 'Profile Update',
        message: 'I am unable to upload my profile picture. It keeps showing an error.',
        issueScreenshot: 'https://via.placeholder.com/150',
        imageDescription: 'Profile picture upload error message',
        requestStatus: 'Resolved',
        requestDate: '2024-09-14',
        customerName: 'Emily Watson',
        customerImage: 'https://randomuser.me/api/portraits/women/5.jpg'
    },
    {
        requestId: 'REQ1722',
        requestSubject: 'Error in generating reports',
        requestType: 'Technical Issue',
        requestCategory: 'Reporting',
        requestSubcategory: 'Report Generation',
        message: 'The reports for last month are not generating correctly. The data seems to be missing.',
        issueScreenshot: 'https://via.placeholder.com/150',
        imageDescription: 'Screenshot of incomplete report',
        requestStatus: 'Pending',
        requestDate: '2024-09-12',
        customerName: 'Michael Scott',
        customerImage: 'https://randomuser.me/api/portraits/men/6.jpg'
    },
    {
        requestId: 'REQ1678',
        requestSubject: 'Payment not processing',
        requestType: 'Billing Issue',
        requestCategory: 'Payments',
        requestSubcategory: 'Payment Failures',
        message: 'I am trying to complete my purchase but the payment is not being processed.',
        issueScreenshot: 'https://via.placeholder.com/150',
        imageDescription: 'Error message while processing payment',
        requestStatus: 'In Progress',
        requestDate: '2024-09-16',
        customerName: 'John Miller',
        customerImage: 'https://randomuser.me/api/portraits/men/4.jpg'
    },
    {
        requestId: 'REQ9701',
        requestSubject: 'Unable to update profile picture',
        requestType: 'Account Issue',
        requestCategory: 'Profile',
        requestSubcategory: 'Profile Update',
        message: 'I am unable to upload my profile picture. It keeps showing an error.',
        issueScreenshot: 'https://via.placeholder.com/150',
        imageDescription: 'Profile picture upload error message',
        requestStatus: 'Resolved',
        requestDate: '2024-09-14',
        customerName: 'Emily Watson',
        customerImage: 'https://randomuser.me/api/portraits/women/5.jpg'
    },
    {
        requestId: 'REQ8678',
        requestSubject: 'Payment not processing',
        requestType: 'Billing Issue',
        requestCategory: 'Payments',
        requestSubcategory: 'Payment Failures',
        message: 'I am trying to complete my purchase but the payment is not being processed.',
        issueScreenshot: 'https://via.placeholder.com/150',
        imageDescription: 'Error message while processing payment',
        requestStatus: 'In Progress',
        requestDate: '2024-09-16',
        customerName: 'John Miller',
        customerImage: 'https://randomuser.me/api/portraits/men/4.jpg'
    },
    {
        requestId: 'REQ1101',
        requestSubject: 'Unable to update profile picture',
        requestType: 'Account Issue',
        requestCategory: 'Profile',
        requestSubcategory: 'Profile Update',
        message: 'I am unable to upload my profile picture. It keeps showing an error.',
        issueScreenshot: 'https://via.placeholder.com/150',
        imageDescription: 'Profile picture upload error message',
        requestStatus: 'Resolved',
        requestDate: '2024-09-14',
        customerName: 'Emily Watson',
        customerImage: 'https://randomuser.me/api/portraits/women/5.jpg'
    },
    {
        requestId: 'REQ1622',
        requestSubject: 'Error in generating reports',
        requestType: 'Technical Issue',
        requestCategory: 'Reporting',
        requestSubcategory: 'Report Generation',
        message: 'The reports for last month are not generating correctly. The data seems to be missing.',
        issueScreenshot: 'https://via.placeholder.com/150',
        imageDescription: 'Screenshot of incomplete report',
        requestStatus: 'Pending',
        requestDate: '2024-09-12',
        customerName: 'Michael Scott',
        customerImage: 'https://randomuser.me/api/portraits/men/6.jpg'
    },
    {
        requestId: 'REQ1678',
        requestSubject: 'Payment not processing',
        requestType: 'Billing Issue',
        requestCategory: 'Payments',
        requestSubcategory: 'Payment Failures',
        message: 'I am trying to complete my purchase but the payment is not being processed.',
        issueScreenshot: 'https://via.placeholder.com/150',
        imageDescription: 'Error message while processing payment',
        requestStatus: 'In Progress',
        requestDate: '2024-09-16',
        customerName: 'John Miller',
        customerImage: 'https://randomuser.me/api/portraits/men/4.jpg'
    },
    {
        requestId: 'REQ4101',
        requestSubject: 'Unable to update profile picture',
        requestType: 'Account Issue',
        requestCategory: 'Profile',
        requestSubcategory: 'Profile Update',
        message: 'I am unable to upload my profile picture. It keeps showing an error.',
        issueScreenshot: 'https://via.placeholder.com/150',
        imageDescription: 'Profile picture upload error message',
        requestStatus: 'Resolved',
        requestDate: '2024-09-14',
        customerName: 'Emily Watson',
        customerImage: 'https://randomuser.me/api/portraits/women/5.jpg'
    },
    {
        requestId: 'REQ7678',
        requestSubject: 'Payment not processing',
        requestType: 'Billing Issue',
        requestCategory: 'Payments',
        requestSubcategory: 'Payment Failures',
        message: 'I am trying to complete my purchase but the payment is not being processed.',
        issueScreenshot: 'https://via.placeholder.com/150',
        imageDescription: 'Error message while processing payment',
        requestStatus: 'In Progress',
        requestDate: '2024-09-16',
        customerName: 'John Miller',
        customerImage: 'https://randomuser.me/api/portraits/men/4.jpg'
    },
    {
        requestId: 'REQ5101',
        requestSubject: 'Unable to update profile picture',
        requestType: 'Account Issue',
        requestCategory: 'Profile',
        requestSubcategory: 'Profile Update',
        message: 'I am unable to upload my profile picture. It keeps showing an error.',
        issueScreenshot: 'https://via.placeholder.com/150',
        imageDescription: 'Profile picture upload error message',
        requestStatus: 'Resolved',
        requestDate: '2024-09-14',
        customerName: 'Emily Watson',
        customerImage: 'https://randomuser.me/api/portraits/women/5.jpg'
    },
];


export default function ContactSupport() {
    const [mainImage, setMainImage] = useState(null);

    const handleMainImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setMainImage(imageUrl);
        }
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [ordersPerPage, setOrdersPerPage] = useState(9); // Default is 3 orders per page

    // Calculate the indexes of orders for the current page
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    const [filteredOrders, setFilteredOrders] = useState(requests.slice(indexOfFirstOrder, indexOfLastOrder));

    const nextPage = () => {
        if (currentPage < Math.ceil(requests.length / ordersPerPage)) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };
    const [search, setSearch] = useState('');


    useEffect(() => {
        const filteredOrdersTemp = requests;
        console.log(indexOfFirstOrder, indexOfLastOrder);
        const slicedFilters = filteredOrdersTemp.slice(indexOfFirstOrder, indexOfLastOrder);
        setFilteredOrders(slicedFilters);
    }, [search, ordersPerPage, currentPage, indexOfFirstOrder, indexOfLastOrder]);

    const getStatusColor = (status) => {
        switch (status) {
            case 'Open':
                return '#ffc107';  // Yellow color for open
            case 'In Progress':
                return '#17a2b8';  // Teal color for in progress
            case 'Resolved':
                return '#28a745';  // Green color for resolved
            case 'Pending':
                return '#007bff';  // Blue color for pending
            default:
                return '#6c757d';  // Gray color for other statuses
        }
    };


    return (
        <div className='add-product'>
            <div className="add-product-header">
                <div>
                    <h1>Contact Support</h1>
                    <p style={{ marginTop: -20 }}>Contact admin support for any issues</p>
                </div>
                {/* <div style={{ display: 'flex' }}>
                    <button className="bulk-update-button">View Raised Requests</button>
                </div> */}
            </div>
            <div className="product-form" style={{ display: 'flex', columnCount: 'auto' }}>
                <div className="form-section basic-info" style={{ width: '60%' }}>
                    <h3>Support Request Information</h3>
                    <label htmlFor="request-subject">Request Subject</label>
                    <div className='product-input'>
                        <input type="text" placeholder="Request Subject" />
                    </div>
                    <label htmlFor="request-type">Request Type</label>
                    <div className='product-select'>
                        <select id="request-type" defaultValue="">
                            <option value="" disabled>Select Request Type</option>
                            <option value="Technical Issue">Technical Issue</option>
                            <option value="Billing Issue">Billing Issue</option>
                            <option value="Account Issue">Account Issue</option>
                            <option value="Product Inquiry">Product Inquiry</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>

                    <label htmlFor="request-category">Request Category</label>
                    <div className='product-select'>
                        <select id="request-category" defaultValue="">
                            <option value="" disabled>Select Request Category</option>
                            <option value="Dashboard">Dashboard</option>
                            <option value="Payments">Payments</option>
                            <option value="Profile">Profile</option>
                            <option value="Orders">Orders</option>
                            <option value="General">General</option>
                        </select>
                    </div>

                    <label htmlFor="request-subcategory">Request Subcategory</label>
                    <div className='product-select'>
                        <select id="request-subcategory" defaultValue="">
                            <option value="" disabled>Select Request Subcategory</option>
                            <option value="Access Issues">Access Issues</option>
                            <option value="Payment Failures">Payment Failures</option>
                            <option value="Profile Update">Profile Update</option>
                            <option value="Order Tracking">Order Tracking</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>

                    <label htmlFor="request-subcategory">Message</label>
                    <div className='product-textarea'>
                        <textarea placeholder="Message"></textarea>
                    </div>
                    <div className="image-upload">
                        <div className="main-image">
                            <label htmlFor="request-subcategory">Issue Screenshot</label>
                            <div className="image-placeholder-main">
                                {mainImage ? (
                                    <img src={mainImage} alt="Main" className="uploaded-image" />
                                ) : (
                                    <>
                                        <label htmlFor="main-image-upload" className="image-upload-label" style={{ width: '100%', height: '100%' }}>
                                            <BiPlus size={40} />
                                        </label>
                                        <input
                                            type="file"
                                            id="main-image-upload"
                                            style={{ display: 'none' }}
                                            onChange={handleMainImageChange}
                                            accept="image/*"
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                        <div style={{ width: "100%" }}>
                            <label htmlFor="product-name" style={{ width: '40%' }}>Image Description</label>
                            <div className='product-input'>
                                <input type="text" placeholder="Short description" />
                            </div>
                        </div>
                    </div>
                    {/* Submit request button */}
                    <button className="bulk-update-button" style={{ width: '100%', margin: 0, marginTop: 10 }}>Submit Request</button>
                </div>
                <div className="form-section product-images">
                    <div className="table-container">
                        <table className="product-table">
                            <thead>
                                <tr>
                                    <th>Request ID</th>
                                    {/* <th>Customer</th> */}
                                    <th>Request Subject</th>
                                    <th>Request Type</th>
                                    <th>Status</th>
                                    <th>Request Date</th>
                                    {/* <th>Actions</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filteredOrders.map((request, index) => (
                                        <tr key={request.requestId} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f8f8f8' }}>
                                            <td> <div
                                                style={{
                                                    width: 100,
                                                    display: 'inline-block'
                                                }}
                                            >
                                                {request.requestId}
                                            </div></td>
                                            {/* <td>
                                                <img src={request.customerImage} alt="customer" className='orders-profile-picture' />
                                                {request.customerName}
                                            </td> */}
                                            <td>
                                                <div
                                                    style={{
                                                        width: 200,
                                                        display: 'inline-block'
                                                    }}
                                                >{request.requestSubject}
                                                </div>
                                            </td>
                                            <td>
                                                <div
                                                    style={{
                                                        width: 120,
                                                        display: 'inline-block'
                                                    }}
                                                >
                                                    {request.requestType}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="order-item">
                                                    <div
                                                        className="order-status"
                                                        style={{
                                                            border: `3px solid ${getStatusColor(request.requestStatus)}`,
                                                            backgroundColor: '#fff',
                                                            padding: '10px',
                                                            textAlign: 'center',
                                                            borderRadius: 15,
                                                            width: 100,
                                                            display: 'inline-block'
                                                        }}
                                                    >
                                                        {request.requestStatus}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div
                                                    style={{
                                                        width: 80,
                                                        display: 'inline-block'
                                                    }}
                                                >
                                                    {request.requestDate}
                                                </div></td>
                                            {/* <td>
                                                <button className='product-table-edit-btn'>Edit</button>
                                            </td> */}
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>


                        <div className="pagination">
                            <span>
                                Showing {indexOfFirstOrder + 1} - {Math.min(indexOfLastOrder, requests.length)} of {requests.length} Requests
                            </span>
                            <div className="pagination-controls">
                                <button className="prev-btn" onClick={prevPage} disabled={currentPage === 1}>
                                    <BiChevronLeft size={40} color='black' />
                                </button>
                                <span>{currentPage} / {Math.ceil(requests.length / ordersPerPage)}</span>
                                <button className="next-btn" onClick={nextPage} disabled={currentPage === Math.ceil(requests.length / ordersPerPage)}>
                                    <BiChevronRight size={40} color='black' />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
