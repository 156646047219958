import React from 'react'
import BruceStark from '../assets/sampleImages/creators/bruce-stark.jpg'
import JackMarsten from '../assets/sampleImages/creators/jack-marsten.jpg'
import MaryWatson from '../assets/sampleImages/creators/mary-watson.jpg'
import PeterBenedict from '../assets/sampleImages/creators/peter-benedict.jpg'
import SerenaSmith from '../assets/sampleImages/creators/serena-smith.jpg'
import ZackWilliams from '../assets/sampleImages/creators/zack-williams.jpg'

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';
export default function PopularCreators() {
    const creatorsData = [
        {
            id: 1,
            name: 'Bruce Stark',
            followers: "100K",
            affiliatedPosts: 20,
            image: BruceStark,
        },
        {
            id: 2,
            name: 'Jack Marsten',
            followers: "90K",
            affiliatedPosts: 16,
            image: JackMarsten
        },
        {
            id: 3,
            name: 'Mary Watson',
            followers: "80K",
            affiliatedPosts: 15,
            image: MaryWatson
        },
        {
            id: 4,
            name: 'Peter Benedict',
            followers: "70K",
            affiliatedPosts: 14,
            image: PeterBenedict
        },
        {
            id: 5,
            name: 'Serena Smith',
            followers: "60K",
            affiliatedPosts: 12,
            image: SerenaSmith
        },
        {
            id: 6,
            name: 'Zack Williams',
            followers: "50K",
            affiliatedPosts: 10,
            image: ZackWilliams
        }

    ]
    return (
        <div className='popular-products-container'>
            <h3 className='popular-products-title'>Most Popular Creators</h3>
            <div className='product-cards-container'>
                <Swiper
                    // install Swiper modules
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    slidesPerView={6}
                    spaceBetween={10}
                    navigation
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log('slide change')}
                >
                    {creatorsData.map((creator) => (
                        <SwiperSlide>
                            <div key={creator.id} className='popular-products-card'>
                                <img src={creator.image} alt={creator.name} />
                                <div className='product-details'>
                                    <h3>{creator.name}</h3>
                                    <span className='creators-stats'>Followers
                                        <span>{creator.followers}</span>
                                    </span>
                                    <span className='creators-stats'>Affiliated Posts
                                        <span>{creator.affiliatedPosts}</span>
                                    </span>
                                </div>
                            </div>
                        </SwiperSlide>

                    ))
                    }
                </Swiper>
            </div>
        </div>
    )
}
