import React, { useState } from 'react'
import GlitchText from '../components/GlitchText'
import GlitchTextAnimated from '../components/GlitchTextAnimated'
import { fontSize, height } from '@mui/system'
import { BsApple, BsGoogle } from 'react-icons/bs'
import { Link } from 'react-router-dom'

export default function SignUp() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log('Login attempt with:', { email, password })
    }
    return (
        <div className='signin-container'>
            <div className='signin-left'>
                <div className='signin-left-glitch-container'>
                    <h1 className='lynkd-logo'>
                        lynkd
                    </h1>
                </div>
            </div>
            <div className='signin-right'>
                <div className="sign-in-container">
                    <h2 className="sign-in-title">Sign Up</h2>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="email" className="sign-in-label">
                            Email
                        </label>
                        <input
                            type="email"
                            placeholder="Email"
                            className="sign-in-input"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <label htmlFor="password" className="sign-in-label">
                            Password
                        </label>
                        <input
                            type="password"
                            placeholder="Password"
                            className="sign-in-input"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <label htmlFor="password" className="sign-in-label">
                            Confirm Password
                        </label>
                        <input
                            type="password"
                            placeholder="Confirm Password"
                            className="sign-in-input"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                        <button type="submit" className="sign-in-button">
                            <Link to="/onboarding" style={{ color: 'black', textDecoration: 'none', width: '100%', display: 'block' }}>
                                Continue
                            </Link>
                        </button>
                    </form>
                    <div className="sign-in-divider">
                        <div className="sign-in-line"></div>
                        <span className="sign-in-or-text">or</span>
                        <div className="sign-in-line"></div>
                    </div>
                    <div className="sign-in-social-buttons">
                        <button className="sign-in-social-button">
                            <span className="sign-in-social-icon"><BsGoogle size={30} /></span> Google
                        </button>
                        <button className="sign-in-social-button">
                            <span className="sign-in-social-icon"><BsApple size={30} /></span> Apple
                        </button>
                    </div>
                    <div className="sign-in-signup-text">
                        Already have an account?
                        <button className="sign-in-signup-button">
                            <Link to="/sign-in" style={{ color: 'white', textDecoration: 'none', width: '100%', display: 'block' }}>
                                Sign In
                            </Link>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

