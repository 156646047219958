import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Dashboard from "./pages/Dashboard";
import "./App.css";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import SignUpDetails from "./pages/SignUpDetails";
import OnboardingWaiting from "./pages/OnboardingWaiting";
import Shop from "./pages/Shop";
import AddProduct from "./pages/AddProduct";
import ChatMessages from "./pages/ChatMessages";
import Orders from "./pages/Orders";
import ContactSupport from "./pages/ContactSupport";
import Ads from "./pages/Ads";
import CreateAdCampaign from "./pages/CreateAdCampaign";
import BrandAmbassadors from "./pages/BrandAmbassadors";
import BrandAmbassadorsRequests from "./pages/BrandAmbassadorsRequests";
function AppRoutes() {
  const activePage = window.location.pathname;
  const location = useLocation();

  useEffect(() => {
    const activePage = location.pathname;
    if (activePage === '/sign-in' || activePage === '/sign-up' || activePage === '/onboarding' || activePage === '/onboarding-pending') {
      document.querySelector('.sidebar').style.display = 'none';
    } else {
      document.querySelector('.sidebar').style.display = 'flex';
    }
  }, [location]);
  return (
    <div className="app">
      {/* if route is not login then show side bar */}
      {/* {
          (activePage !== '/sign-in' && activePage !== '/sign-up') && <Sidebar />
        } */}
      <Sidebar />
      <Routes>
        <Route path="/" element={<Navigate to="/sign-in" />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/onboarding" element={<SignUpDetails />} />
        <Route path="/onboarding-pending" element={<OnboardingWaiting />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/shop/add-product" element={<AddProduct />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/messages" element={<ChatMessages />} />
        <Route path="/ads-management" element={<Ads />} />
        <Route path="/ads-management/create-campaign" element={<CreateAdCampaign />} />
        <Route path="/brand-ambassadors" element={<BrandAmbassadors />} />
        <Route path="/brand-ambassadors/requests" element={<BrandAmbassadorsRequests />} />
        <Route path="/contact-support" element={<ContactSupport />} />
        {/* Add routes for other pages like Orders, Shipments etc. */}
      </Routes>
    </div>
  );
}

export default function App() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  )
}
;